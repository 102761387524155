/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import { onValue, ref } from 'firebase/database';
import md5 from 'md5';
import React, { useEffect, useState } from 'react';
import ConfigApi from '../../../../configs/ConfigApi';
import { useAuth } from '../../../contexts/AuthProvider';
import TimestampToDate from '../../../models/TimestampToDate';
import { rtDb } from '../../../MyFirebase';
import AxiosAuth from '../../../utils/AxiosAuth';

const setCommentArticleAr = (commentAr, commentArticleAr, currentUser) => {
    Object.keys(commentAr)?.map((comMain) => {
        Object.values(commentAr[comMain])?.map((comSub) => {
            if (comSub?.profileEmail === currentUser?.email) {
                if (comSub.childes) {
                    Object.values(comSub.childes)?.map((comChi) =>
                        commentArticleAr.push({
                            sl: comMain,
                            time_execute: comChi?.time,
                            title: `${comChi?.profileName} Replay Your Comment`,
                            link: `https://www.bahannonews.com/details/article/${comMain}/`
                        })
                    );
                }
                if (comSub.likeCount !== 0 || comSub.dislikeCount !== 0) {
                    commentArticleAr.push({
                        sl: comMain,
                        time_execute: comSub?.time,
                        title: `Activity Your Comment`,
                        link: `https://www.bahannonews.com/details/article/${comMain}/`
                    });
                }
            }
            return null;
        });

        return null;
    });
};

function NotificationInfo() {
    const [notificationAr, setNotificationAr] = useState([]);
    const [commentAr, setCommentAr] = useState();
    const [notificationReadAr, setNotificationReadAr] = useState([]);

    const { currentUser } = useAuth();
    const commentArticleAr = [];

    if (commentAr && currentUser?.email) {
        setCommentArticleAr(commentAr, commentArticleAr, currentUser);
    }

    const notificationAllAr = [...notificationAr, ...commentArticleAr];
    const handleLink = (ev, ev2) => {
        AxiosAuth.currentUserAuth(currentUser)
            .post(ConfigApi.API_NOTI_READ_SET, {
                hash: ev
            })
            .then(({ data }) => {
                setNotificationReadAr(data);
            });
        // navigate(ev2);
        window.location.href = ev2;
    };
    useEffect(() => {
        AxiosAuth.get(ConfigApi.API_NOTIFICATION).then(({ data }) => {
            setNotificationAr(data);
        });

        AxiosAuth.currentUserAuth(currentUser)
            .post(ConfigApi.API_NOTI_READ)
            .then(({ data }) => {
                setNotificationReadAr(data);
            });
    }, [currentUser, currentUser.email]);

    useEffect(() => {
        const dbRef = ref(rtDb, `/comments`);

        onValue(dbRef, (snapshot) => {
            setCommentAr(snapshot.val());
        });
    }, [currentUser.email]);
    return (
        <div className="profile-sidebar">
            <div className="top-notification">
                <ul>
                    {Object.values(notificationAllAr)
                        ?.sort((item1, item2) => {
                            if (item1.time_execute > item2.time_execute) {
                                return -1;
                            }
                            if (item1.time_execute < item2.time_execute) {
                                return 1;
                            }

                            return 0;
                        })
                        .map((row) => {
                            const notiMd5 = md5(
                                `${currentUser?.email}-${row.sl}-${row.time_execute}-${row?.link}`
                            );
                            const notiLink = row?.link;
                            return (
                                <li key={`${row.sl}-${row.time_execute}`}>
                                    <p
                                        onClick={() => handleLink(notiMd5, notiLink)}
                                        style={{
                                            color: notificationReadAr[notiMd5]
                                                ? 'rgba(17, 17, 17, 0.6)'
                                                : ''
                                        }}
                                    >
                                        {row?.title}
                                    </p>
                                    <p>
                                        <TimestampToDate time={row.time_execute} />
                                    </p>
                                </li>
                            );
                        })}
                </ul>
            </div>
            {/* <div className="top-notification-btn">
                <Link role="button" to="/">
                    আরও
                </Link>
            </div> */}
        </div>
    );
}

export default NotificationInfo;
