import React, { useEffect, useRef } from 'react';

function ScrollToTop() {
    const handelScrollTop = () => {
        window.scrollTo(0, 0);
    };

    const ref = useRef(null);

    useEffect(() => {
        const handleScroll = () => {
            const currentScrollY = window.scrollY;

            if (ref !== null) {
                if (currentScrollY > 200) {
                    ref.current.classList.add('show');
                } else {
                    ref.current.classList.remove('show');
                }
            }
        };

        window.addEventListener('scroll', handleScroll, { passive: true });

        return () => window.removeEventListener('scroll', handleScroll);
    }, []);

    return (
        <>
            <div
                ref={ref}
                className="scroll-to-top animate"
                onClick={handelScrollTop}
                role="button"
                tabIndex="-1"
            >
                {/* <svg
                    aria-hidden="true"
                    focusable="false"
                    data-prefix="fal"
                    data-icon="arrow-circle-up"
                    role="img"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 512 512"
                    className="svg-inline--fa fa-arrow-circle-up fa-w-16 fa-9x"
                    style={{ width: '37px' }}
                >
                    <path
                        fill="currentColor"
                        d="M256 504c137 0 248-111 248-248S393 8 256 8 8 119 8 256s111 248 248 248zM40 256c0-118.7 96.1-216 216-216 118.7 0 216 96.1 216 216 0 118.7-96.1 216-216 216-118.7 0-216-96.1-216-216zm92.5-12.5l115-115.1c4.7-4.7 12.3-4.7 17 0l115 115.1c4.7 4.7 4.7 12.3 0 17l-6.9 6.9c-4.7 4.7-12.5 4.7-17.1-.2L273 181.7V372c0 6.6-5.4 12-12 12h-10c-6.6 0-12-5.4-12-12V181.7l-82.5 85.6c-4.7 4.8-12.4 4.9-17.1.2l-6.9-6.9c-4.7-4.8-4.7-12.4 0-17.1z"
                        className=""
                    />
                </svg> */}
                <svg
                    width="37"
                    height="37"
                    viewBox="0 0 37 37"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        d="M17.9911 14.3672C18.5491 13.8789 19.4554 13.8789 20.0134 14.3672L27.1563 20.6172C27.7143 21.1055 27.7143 21.8984 27.1563 22.3867C26.5982 22.875 25.692 22.875 25.1339 22.3867L19 17.0195L12.8661 22.3828C12.308 22.8711 11.4018 22.8711 10.8438 22.3828C10.2857 21.8945 10.2857 21.1016 10.8438 20.6133L17.9866 14.3633L17.9911 14.3672Z"
                        fill="#07A75C"
                    />
                    <circle cx="18.5" cy="18.5" r="17" stroke="#07A75C" strokeWidth="3" />
                </svg>
            </div>
        </>
    );
}

export default ScrollToTop;
