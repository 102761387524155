import { useEffect, useState } from 'react';
import AdSense from 'react-adsense';
import { Bling } from 'react-gpt';
import { useLocation } from 'react-router-dom';
import adBsb from '../../assets/images/Cambrian_300_300.png';
// import adsKbl from '../../assets/images/Milestone.gif';rokomari

import adsPangree from '../../assets/images/Panjeree-2.gif';
import adsTen from '../../assets/images/Trust_970_90.gif';
import ads20 from '../../assets/images/bik_add2.jpg';
import ads2 from '../../assets/images/bikiran-add-2.png';
import ads1 from '../../assets/images/bikiran-banner-1.png';
import globalassistant from '../../assets/images/globalassistant.gif';
import adsUcc from '../../assets/images/icc.gif';
import adsUcc2 from '../../assets/images/icc300x250.gif';
import adsTwelve from '../../assets/images/onnorokom_300_250.gif';
import adsSeventeen from '../../assets/images/onnorokom_728_90.gif';
import adsEleven from '../../assets/images/onnorokom_900.gif';
import adsPbs from '../../assets/images/pbs.gif';
import adsFive from '../../assets/images/trust-clg-03.jpg';
import adsFive1 from '../../assets/images/trust-clg-04.jpg';

import adsGoogle from '../../assets/images/GoogleFrame .jpg';
import adsYoutube from '../../assets/images/YtFrame.jpg';
import udvash from '../../assets/images/udvash1.png';

export function Ads01({ show = false }) {
    if (!show) {
        return null;
    }

    return (
        <div className="ads-01 d-lg-none">
            <a href="https://sheikhrussel.gov.bd/" target="_blank" rel="noreferrer">
                <img
                    src="https://www.bahannonews.com/assets/images/ads-rasel-03.gif"
                    alt="www.bahannonews.com"
                />
            </a>
        </div>
    );
}

export function Ads02({ show = false }) {
    if (!show) {
        return null;
    }

    return (
        <div className="col-12 d-none d-lg-block d-xl-block">
            <div className="ad-one">
                <img src={ads1} alt="www.bahannonews.com" />
            </div>
        </div>
    );
}

export function Ads03({ show = false }) {
    if (!show) {
        return null;
    }
    return (
        <div className="ads-01 d-none d-lg-block">
            <a href="https://sheikhrussel.gov.bd/" target="_blank" rel="noreferrer">
                <img
                    src="https://www.bahannonews.com/assets/images/ads-rasel-01.gif"
                    alt="www.bahannonews.com"
                />
            </a>
        </div>
    );
}

export function Ads04({ show = false }) {
    if (!show) {
        return null;
    }
    return (
        <div className="ads-01" style={{ marginTop: '-10px' }}>
            <a href="https://sheikhrussel.gov.bd/" target="_blank" rel="noreferrer">
                <img
                    src="https://www.bahannonews.com/assets/images/ads-rasel-02.gif"
                    alt="www.bahannonews.com"
                    style={{ width: '100%' }}
                />
            </a>
        </div>
    );
}
export function Ads05({ show = false, mode }) {
    const images = [udvash, adBsb];
    const link = ['https://udvash.com/Program', 'http://www.bsbbd.com/'];
    const [currentIndex, setCurrentIndex] = useState(0);
    const { search } = useLocation();

    const [adSetup, setAdSetup] = useState('');

    useEffect(() => {
        setAdSetup(new URLSearchParams(search).get('ads'));

        const intervalId = setInterval(() => {
            if (currentIndex === images.length - 1) {
                setCurrentIndex(0);
            } else {
                setCurrentIndex(currentIndex + 1);
            }
        }, 4000);

        return () => clearInterval(intervalId);
    }, [currentIndex, images.length, search]);

    if (!show) {
        return null;
    }

    const typeData = adSetup?.split('_');
    if (typeData?.length > 0) {
        const adLink = `${process.env.REACT_APP_API_URL}/manage/ads/manage/?id=${typeData[1]}-${typeData[2]}-02&size=300x250`;

        return (
            <div className="sidebar-add-one" style={{ marginBottom: 24 }}>
                <a href={adLink} target="_blank" rel="noreferrer">
                    <img
                        src={ads2}
                        alt="www.bahannonews.com"
                        style={{ width: '100%', height: '250px' }}
                    />
                </a>
            </div>
        );
    }

    if (mode === 'placeholder') {
        return (
            <div className="sidebar-add-one">
                <img src={ads2} alt="www.bahannonews.com" style={{ width: '100%' }} />
            </div>
        );
    }

    return (
        <div className="sidebar-add-one">
            <a href={link[currentIndex]} target="_blank" rel="noreferrer">
                <img
                    src={images[currentIndex]}
                    alt="www.bahannonews.com"
                    className="w-100"
                    style={{ height: '100%' }}
                />
            </a>
        </div>
    );
}

export function Ads055({ show = false, mode }) {
    const images = [globalassistant];
    const link = ['https://globalassistant.info/'];
    const [currentIndex, setCurrentIndex] = useState(0);
    const { search } = useLocation();

    const [adSetup, setAdSetup] = useState('');

    useEffect(() => {
        setAdSetup(new URLSearchParams(search).get('ads'));

        const intervalId = setInterval(() => {
            if (currentIndex === images.length - 1) {
                setCurrentIndex(0);
            } else {
                setCurrentIndex(currentIndex + 1);
            }
        }, 4000);

        return () => clearInterval(intervalId);
    }, [currentIndex, images.length, search]);

    if (!show) {
        return null;
    }

    const typeData = adSetup?.split('_');
    if (typeData?.length > 0) {
        const adLink = `${process.env.REACT_APP_API_URL}/manage/ads/manage/?id=${typeData[1]}-${typeData[2]}-02&size=300x250`;

        return (
            <div className="sidebar-add-one" style={{ marginBottom: 24 }}>
                <a href={adLink} target="_blank" rel="noreferrer">
                    <img
                        src={ads2}
                        alt="www.bahannonews.com"
                        style={{ width: '100%', height: '250px' }}
                    />
                </a>
            </div>
        );
    }

    if (mode === 'placeholder') {
        return (
            <div className="sidebar-add-one">
                <img src={ads2} alt="www.bahannonews.com" style={{ width: '100%' }} />
            </div>
        );
    }

    return (
        <div className="sidebar-add-one">
            <a href={link[currentIndex]} target="_blank" rel="noreferrer">
                <img
                    src={images[currentIndex]}
                    alt="www.bahannonews.com"
                    className="w-100"
                    style={{ height: '100%' }}
                />
            </a>
        </div>
    );
}
export function Ads06({ show = false, mode }) {
    const images = [adBsb, adBsb];
    const link = ['http://www.bsbbd.com/', 'http://www.bsbbd.com/'];
    const [currentIndex, setCurrentIndex] = useState(0);
    const { search } = useLocation();

    const [adSetup, setAdSetup] = useState('');
    useEffect(() => {
        setAdSetup(new URLSearchParams(search).get('ads'));

        const intervalId = setInterval(() => {
            if (currentIndex === images.length - 1) {
                setCurrentIndex(0);
            } else {
                setCurrentIndex(currentIndex + 1);
            }
        }, 7000);

        return () => clearInterval(intervalId);
    }, [currentIndex, images.length, search]);
    const typeData = adSetup?.split('_');

    if (!show) {
        return null;
    }
    if (typeData?.length > 0) {
        const adLink = `${process.env.REACT_APP_API_URL}/manage/ads/manage/?id=${typeData[1]}-${typeData[2]}-05&size=300x250`;

        return (
            <div className="sidebar-add-one not-print-class" style={{ marginBottom: 24 }}>
                <a href={adLink} target="_blank" rel="noreferrer">
                    <img
                        src={ads2}
                        alt="www.bahannonews.com"
                        style={{ width: '100%', height: '250px' }}
                    />
                </a>
            </div>
        );
    }
    if (mode === 'placeholder') {
        return (
            <div className="sidebar-add-one not-print-class">
                <img src={ads2} alt="www.bahannonews.com" style={{ width: '100%' }} />
            </div>
        );
    }

    return (
        <div className="sidebar-add-one not-print-class">
            <a href={link[currentIndex]} target="_blank" rel="noreferrer">
                <img
                    src={images[currentIndex]}
                    alt="www.bahannonews.com"
                    className="w-100"
                    style={{ height: '100%' }}
                />
            </a>
        </div>
    );
}
export function Ads061({ show = false, mode }) {
    const { search } = useLocation();

    const [adSetup, setAdSetup] = useState('');
    useEffect(() => {
        setAdSetup(new URLSearchParams(search).get('ads'));
    }, [search]);
    const typeData = adSetup?.split('_');
    if (!show) {
        return null;
    }
    if (typeData?.length > 0) {
        const adLink = `${process.env.REACT_APP_API_URL}/manage/ads/manage/?id=${typeData[1]}-${typeData[2]}-08&size=300x250`;

        return (
            <div className="sidebar-add-one" style={{ marginBottom: 24 }}>
                <a href={adLink} target="_blank" rel="noreferrer">
                    <img src={ads2} alt="www.bahannonews.com" style={{ width: '100%' }} />
                </a>
            </div>
        );
    }
    if (mode === 'placeholder') {
        return (
            <div className="sidebar-add-one">
                <img src={ads2} alt="www.bahannonews.com" style={{ width: '100%' }} />
            </div>
        );
    }

    return (
        <div className="sidebar-add-one">
            <div id="gpt-passback6">
                {/* <Gpt
                    adUnit="/21633305394/Hyper_Local_300x250"
                    name="gpt-passback6"
                    size={[300, 250]}
                /> */}
                <Bling adUnitPath="/37972922/BahannoHome-340x250" slotSize={[250, 250]} />
            </div>
        </div>
    );
}
export function Ads07({ show = false, mode, name = 'gpt-passback7' }) {
    if (!show) {
        return null;
    }

    if (mode === 'placeholder') {
        return (
            <div className="ads-seven">
                <img src={ads2} alt="www.bahannonews.com" style={{ width: '100%' }} />
            </div>
        );
    }

    return (
        <div className="ads-seven">
            <div id={name}>
                {/* <Gpt adUnit="/21633305394/Hyper_Local_728x90" name={name} size={[728, 90]} /> */}
            </div>
        </div>
    );
}
export function Ads08({ show = false, mode }) {
    if (!show) {
        return null;
    }

    if (mode === 'placeholder') {
        return (
            <div className="sidebar-add-one">
                <img src={ads2} alt="www.bahannonews.com" style={{ width: '100%' }} />
            </div>
        );
    }

    return (
        <div className="sidebar-add-eight not-print-class">
            <a href="https://pbs.com.bd" target="_blank" rel="noreferrer">
                <img
                    src={adsPbs}
                    alt="www.bahannonews.com"
                    className="w-100"
                    style={{ height: '100%' }}
                />
            </a>
        </div>
    );
}

export function Ads155({ show = false, mode }) {
    if (!show) {
        return null;
    }

    if (mode === 'placeholder') {
        return (
            <div className="sidebar-add-one">
                <img src={ads2} alt="www.bahannonews.com" style={{ width: '100%' }} />
            </div>
        );
    }

    return (
        <div className="sidebar-add-eight not-print-class">
            <a href="https://udvash.com/Program" target="_blank" rel="noreferrer">
                <img
                    src={udvash}
                    alt="www.bahannonews.com"
                    className="w-100"
                    style={{ height: '100%' }}
                />
            </a>
        </div>
    );
}
export function Ads088({ show = false, mode }) {
    if (!show) {
        return null;
    }

    if (mode === 'placeholder') {
        return (
            <div className="sidebar-add-one">
                <img src={ads2} alt="www.bahannonews.com" style={{ width: '100%' }} />
            </div>
        );
    }

    return (
        <div className="sidebar-add-eight not-print-class">
            <a href="https://globalassistant.info/" target="_blank" rel="noreferrer">
                <img
                    src={globalassistant}
                    alt="www.bahannonews.com"
                    className="w-100"
                    style={{ height: '100%' }}
                />
            </a>
        </div>
    );
}
export function Ads089({ show = false, mode }) {
    if (!show) {
        return null;
    }

    if (mode === 'placeholder') {
        return (
            <div className="sidebar-add-one">
                <img src={ads2} alt="www.bahannonews.com" style={{ width: '100%' }} />
            </div>
        );
    }

    return (
        <div className="sidebar-add-eight not-print-class">
            <a href="https://www.facebook.com/kbdmasum" target="_blank" rel="noreferrer">
                <img
                    src={adsUcc2}
                    alt="www.bahannonews.com"
                    className="w-100"
                    style={{ height: '100%' }}
                />
            </a>
        </div>
    );
}
export function Ads099({ show = false, mode }) {
    const images = [adsUcc2, adsUcc2];
    const link = ['https://www.facebook.com/kbdmasum', 'https://www.facebook.com/kbdmasum'];
    const [currentIndex, setCurrentIndex] = useState(0);

    useEffect(() => {
        const intervalId = setInterval(() => {
            if (currentIndex === images.length - 1) {
                setCurrentIndex(0);
            } else {
                setCurrentIndex(currentIndex + 1);
            }
        }, 7000);

        return () => clearInterval(intervalId);
    }, [currentIndex, images.length]);

    if (!show) {
        return null;
    }

    if (mode === 'placeholder') {
        return (
            <div className="sidebar-add-one">
                <img src={ads2} alt="www.bahannonews.com" style={{ width: '100%' }} />
            </div>
        );
    }

    return (
        <div className="sidebar-add-one not-print-class">
            <a href={link[currentIndex]} target="_blank" rel="noreferrer">
                <img
                    src={images[currentIndex]}
                    alt="www.bahannonews.com"
                    className="w-100"
                    style={{ height: '100%' }}
                />
            </a>
        </div>
    );
}

export function Ads199({ show = false, mode }) {
    const images = [globalassistant];
    const link = ['https://globalassistant.info/'];
    const [currentIndex, setCurrentIndex] = useState(0);

    useEffect(() => {
        const intervalId = setInterval(() => {
            if (currentIndex === images.length - 1) {
                setCurrentIndex(0);
            } else {
                setCurrentIndex(currentIndex + 1);
            }
        }, 7000);

        return () => clearInterval(intervalId);
    }, [currentIndex, images.length]);

    if (!show) {
        return null;
    }

    if (mode === 'placeholder') {
        return (
            <div className="sidebar-add-one">
                <img src={ads2} alt="www.bahannonews.com" style={{ width: '100%' }} />
            </div>
        );
    }

    return (
        <div className="sidebar-add-one not-print-class">
            <a href={link[currentIndex]} target="_blank" rel="noreferrer">
                <img
                    src={images[currentIndex]}
                    alt="www.bahannonews.com"
                    className="w-100"
                    style={{ height: '100%' }}
                />
            </a>
        </div>
    );
}

export function Ads09({ show = false, mode }) {
    const images = [adsUcc2, adsUcc2];
    const link = ['https://www.facebook.com/kbdmasum', 'https://www.facebook.com/kbdmasum'];
    const [currentIndex, setCurrentIndex] = useState(0);

    useEffect(() => {
        const intervalId = setInterval(() => {
            if (currentIndex === images.length - 1) {
                setCurrentIndex(0);
            } else {
                setCurrentIndex(currentIndex + 1);
            }
        }, 7000);

        return () => clearInterval(intervalId);
    }, [currentIndex, images.length]);

    if (!show) {
        return null;
    }

    if (mode === 'placeholder') {
        return (
            <div className="sidebar-add-one">
                <img src={ads2} alt="www.bahannonews.com" style={{ width: '100%' }} />
            </div>
        );
    }

    return (
        <div className="sidebar-add-one not-print-class">
            <a href={link[currentIndex]} target="_blank" rel="noreferrer">
                <img
                    src={images[currentIndex]}
                    alt="www.bahannonews.com"
                    className="w-100"
                    style={{ height: '100%' }}
                />
            </a>
        </div>
    );
}

// Dynamic Add 1
export function Ads10({ show = false, mode }) {
    const images = [adsEleven, adsEleven];
    const link = ['https://m.me/BigganBaksho?ref=OnlinePortal'];
    const [currentIndex, setCurrentIndex] = useState(0);

    useEffect(() => {
        const intervalId = setInterval(() => {
            if (currentIndex === images.length - 1) {
                setCurrentIndex(0);
            } else {
                setCurrentIndex(currentIndex + 1);
            }
        }, 7000);

        return () => clearInterval(intervalId);
    }, [currentIndex, images.length]);

    if (!show) {
        return null;
    }

    if (mode === 'placeholder') {
        return (
            <div className="sidebar-add-one">
                <img src={ads2} alt="www.bahannonews.com" style={{ width: '100%' }} />
            </div>
        );
    }

    return (
        <div className="add-nine">
            <a href={link[currentIndex]} target="_blank" rel="noreferrer">
                <img
                    src={images[currentIndex]}
                    alt="www.bahannonews.com"
                    className="w-100"
                    style={{ height: 129 }}
                />
            </a>
        </div>
    );
}
export function Ads11({ show = false, mode }) {
    const { search } = useLocation();

    const [adSetup, setAdSetup] = useState('');
    useEffect(() => {
        setAdSetup(new URLSearchParams(search).get('ads'));
    }, [search]);
    const typeData = adSetup?.split('_');

    if (!show) {
        return null;
    }
    if (typeData?.length > 0) {
        const adLink = `${process.env.REACT_APP_API_URL}/manage/ads/manage/?id=${typeData[1]}-${typeData[2]}-09&size=300x250`;

        return (
            <div className="sidebar-add-one" style={{ marginBottom: 24, height: '90px' }}>
                <a href={adLink} target="_blank" rel="noreferrer">
                    <img
                        src={ads2}
                        alt="www.bahannonews.com"
                        style={{ width: '100%', height: '90px' }}
                    />
                </a>
            </div>
        );
    }
    if (mode === 'placeholder') {
        return (
            <div className="sidebar-add-one" style={{ height: '90px' }}>
                <img src={ads1} alt="www.bahannonews.com" style={{ width: '100%' }} />
            </div>
        );
    }

    return (
        <div className="add-nine">
            <a href="https://m.me/BigganBaksho?ref=OnlinePortal" target="_blank" rel="noreferrer">
                <img
                    src={adsEleven}
                    alt="www.bahannonews.com"
                    className="w-100"
                    style={{ height: '100%' }}
                />
            </a>
        </div>
    );
}
export function Ads12({ show = false, mode }) {
    if (!show) {
        return null;
    }

    if (mode === 'placeholder') {
        return (
            <div className="sidebar-add-twelve">
                <img src={ads2} alt="www.bahannonews.com" style={{ width: '100%' }} />
            </div>
        );
    }

    return (
        <div className="sidebar-add-twelve">
            <div id="gpt-passback6">
                {/* <Gpt
                    adUnit="/21633305394/Hyper_Local_300x250"
                    name="gpt-passback6"
                    size={[300, 250]}
                /> */}
            </div>
        </div>
    );
}
export function Ads13({ show = false, mode }) {
    if (!show) {
        return null;
    }

    if (mode === 'placeholder') {
        return (
            <div className="ad-two">
                <img src={ads1} alt="www.bahannonews.com" style={{ width: '100%' }} />
            </div>
        );
    }

    return (
        <div className="ad-two">
            <a href="https://m.me/BigganBaksho?ref=OnlinePortal" target="_blank" rel="noreferrer">
                <img
                    src={adsTwelve}
                    alt="www.bahannonews.com"
                    className="w-100"
                    style={{ height: '100%' }}
                />
            </a>
        </div>
    );
}
export function Ads14({ show = false, mode }) {
    if (!show) {
        return null;
    }

    if (mode === 'placeholder') {
        return (
            <div className="ad-two">
                <img src={ads1} alt="www.bahannonews.com" />
            </div>
        );
    }

    return (
        <div className="ad-two">
            <a href="https://m.me/BigganBaksho?ref=OnlinePortal" target="_blank" rel="noreferrer">
                <img
                    src={adsTwelve}
                    alt="www.bahannonews.com"
                    className="w-100"
                    style={{ height: '100%' }}
                />
            </a>
        </div>
    );
}
export function Ads14Full({ show = false, mode }) {
    const images = [adsTen, adsTen];
    const [currentIndex, setCurrentIndex] = useState(0);
    const { search } = useLocation();

    const [adSetup, setAdSetup] = useState('');

    useEffect(() => {
        setAdSetup(new URLSearchParams(search).get('ads'));
        const intervalId = setInterval(() => {
            if (currentIndex === images.length - 1) {
                setCurrentIndex(0);
            } else {
                setCurrentIndex(currentIndex + 1);
            }
        }, 3000);

        return () => clearInterval(intervalId);
    }, [currentIndex, images.length, search]);

    const typeData = adSetup?.split('_');
    if (!show) {
        return null;
    }
    if (typeData?.length > 0) {
        const adLink = `${process.env.REACT_APP_API_URL}/manage/ads/manage/?id=${typeData[1]}-${typeData[2]}-07&size=780x100`;
        return (
            <div className="ad-two-full" style={{ height: '100px', width: '100%' }}>
                <a href={adLink} target="_blank" rel="noreferrer">
                    <img src={ads1} alt="www.bahannonews.com" style={{ width: '100%' }} />
                </a>
            </div>
        );
    }
    if (mode === 'placeholder') {
        return (
            <div className="ad-two-full" style={{ height: '100px' }}>
                <img src={ads1} alt="www.bahannonews.com" style={{ height: '100%' }} />
            </div>
        );
    }

    return (
        <div className="add-nine not-print-class">
            <a href=" https://trustcollege.edu.bd/" target="_blank" rel="noreferrer">
                <img
                    src={images[currentIndex]}
                    alt="www.bahannonews.com"
                    className="w-100"
                    style={{ height: '100%' }}
                />
            </a>
        </div>
    );
}
export function Ads15({ show = false, mode }) {
    if (!show) {
        return null;
    }

    if (mode === 'placeholder') {
        return (
            <div className="ad-two">
                <img src={ads1} alt="www.bahannonews.com" />
            </div>
        );
    }

    return (
        <div className="ad-two">
            <a href="https://m.me/BigganBaksho?ref=OnlinePortal" target="_blank" rel="noreferrer">
                <img
                    src={adsTwelve}
                    alt="www.bahannonews.com"
                    className="w-100"
                    style={{ height: '100%' }}
                />
            </a>
        </div>
    );
}
export function Ads16({ show = false, mode }) {
    if (!show) {
        return null;
    }

    if (mode === 'placeholder') {
        return (
            <div className="ad-two">
                <img src={ads1} alt="www.bahannonews.com" />
            </div>
        );
    }

    return (
        <div className="ad-two">
            <a href="https://m.me/BigganBaksho?ref=OnlinePortal" target="_blank" rel="noreferrer">
                <img
                    src={adsTwelve}
                    alt="www.bahannonews.com"
                    className="w-100"
                    style={{ height: '100%' }}
                />
            </a>
        </div>
    );
}
export function Ads17({ show = false, mode }) {
    const images = [adsEleven, adsPangree, adsUcc];
    const link = [
        'https://m.me/BigganBaksho?ref=OnlinePortal',
        'https://www.panjeree.com',
        'https://www.facebook.com/kbdmasum'
    ];
    const [currentIndex, setCurrentIndex] = useState(0);
    const { search } = useLocation();

    const [adSetup, setAdSetup] = useState('');

    useEffect(() => {
        setAdSetup(new URLSearchParams(search).get('ads'));

        const intervalId = setInterval(() => {
            if (currentIndex === images.length - 1) {
                setCurrentIndex(0);
            } else {
                setCurrentIndex(currentIndex + 1);
            }
        }, 4000);

        return () => clearInterval(intervalId);
    }, [currentIndex, images.length, search]);

    const typeData = adSetup?.split('_');

    if (!show) {
        return null;
    }
    if (typeData?.length > 0) {
        const adLink = `${process.env.REACT_APP_API_URL}/manage/ads/manage/?id=${typeData[1]}-${typeData[1]}-07&size=780x100`;
        return (
            <div className="sidebar-add-one" style={{ width: '100%', height: 50 }}>
                <a
                    href={adLink}
                    target="_blank"
                    rel="noreferrer"
                    style={{ width: '100%', height: 50 }}
                >
                    <img
                        src={ads2}
                        alt="www.bahannonews.com"
                        style={{ width: '100%', height: 50 }}
                    />
                </a>
            </div>
        );
    }

    if (mode === 'placeholder') {
        return (
            <div className="sidebar-add-one">
                <img src={ads2} alt="www.bahannonews.com" style={{ width: '100%' }} />
            </div>
        );
    }

    return (
        <div className="add-nine not-print-class">
            <a href={link[currentIndex]} target="_blank" rel="noreferrer">
                <img
                    src={images[currentIndex]}
                    alt="www.bahannonews.com"
                    className="w-100 height-130"
                    // style={{ height: '100px' }}
                />
            </a>
        </div>
    );
}
export function Ads18({ show = false, mode }) {
    if (!show) {
        return null;
    }

    if (mode === 'placeholder') {
        return (
            <div className="ad-two">
                <img src={ads1} alt="www.bahannonews.com" style={{ width: '100%' }} />
            </div>
        );
    }

    return (
        <div className="add-nine">
            <a href="https://m.me/BigganBaksho?ref=OnlinePortal" target="_blank" rel="noreferrer">
                <img
                    src={adsSeventeen}
                    alt="www.bahannonews.com"
                    className="w-100"
                    style={{ height: '100px' }}
                />
            </a>
        </div>
    );
}
export function Ads19({ show = false, mode }) {
    if (!show) {
        return null;
    }

    if (mode === 'placeholder') {
        return (
            <div className="sidebar-add-one">
                <img src={ads2} alt="www.bahannonews.com" style={{ width: '100%' }} />
            </div>
        );
    }

    return (
        <div className="sidebar-add-one ads-19">
            {/* <Gpt adUnit="BahannoHome-340x250" name="BahannoHome-340x250" size={[300, 250]} /> */}

            {/* BAH_RESP_H19 */}
            <AdSense.Google
                client="ca-pub-9512882955715493"
                slot="8774250915"
                style={{ display: 'block' }}
                format="auto"
                responsive="true"
                layoutKey="-gw-1+2a-9x+5c"
            />
        </div>
    );
}
export function Ads20({ show = false, mode }) {
    const images = [adsFive, adsFive1];
    const [currentIndex, setCurrentIndex] = useState(0);

    useEffect(() => {
        const intervalId = setInterval(() => {
            if (currentIndex === images.length - 1) {
                setCurrentIndex(0);
            } else {
                setCurrentIndex(currentIndex + 1);
            }
        }, 3000);

        return () => clearInterval(intervalId);
    }, [currentIndex, images.length]);
    if (!show) {
        return null;
    }

    if (mode === 'placeholder') {
        return (
            <div className="sidebar-add-one">
                <img src={ads2} alt="www.bahannonews.com" style={{ width: '100%' }} />
            </div>
        );
    }

    return (
        <div className="sidebar-add-20">
            <a href="http://www.bikiran.com/" target="_blank" rel="noreferrer">
                <img src={ads20} alt="www.bahannonews.com" />
            </a>
        </div>
    );
}

export function AdsYoutube({ show = false, mode }) {
    if (!show) {
        return null;
    }

    if (mode === 'placeholder') {
        return (
            <div className="sidebar-add-one">
                <img src={ads2} alt="www.bahannonews.com" style={{ width: '100%' }} />
            </div>
        );
    }

    return (
        <div className="sidebar-add-20">
            <a
                href="https://www.youtube.com/@bahannonews.official"
                target="_blank"
                rel="noreferrer"
            >
                <img src={adsYoutube} alt="www.bahannonews.com" />
            </a>
        </div>
    );
}

export function AdsGoogle({ show = false, mode }) {
    if (!show) {
        return null;
    }

    if (mode === 'placeholder') {
        return (
            <div className="sidebar-add-one">
                <img src={ads2} alt="www.bahannonews.com" style={{ width: '100%' }} />
            </div>
        );
    }

    return (
        <div className="sidebar-add-20">
            <a
                href="https://news.google.com/publications/CAAqBwgKMNz4qwsw2YPEAw?hl=bn&gl=BD&ceid=BD%3Abn"
                target="_blank"
                rel="noreferrer"
            >
                <img src={adsGoogle} alt="www.bahannonews.com" />
            </a>
        </div>
    );
}
function Ads() {
    return <div />;
}

export default Ads;
