import { Link } from 'react-router-dom';
import { XlHome02, XlHome03, XlHome04, XlHome08 } from '../../../components/AdManagerXl';
// import ConverterImg from '../../../../assets/images/bangla_converter.png';
import ConverterImg from '../../../../assets/images/bangla_converter.png';
import { Ads05, Ads055, Ads061, Ads19, AdsGoogle, AdsYoutube } from '../../../components/Ads';
import FbPagePreview from '../../../components/FbPagePreview';
import LatestAndTopNews from '../../../components/LatestAndTopNews';
import PlayButton from '../../../components/PlayButton';
import BrowserInfo from '../../../utils/BrowserInfo';
import InitInfo from '../../../utils/InitInfo';
import Iopt from '../../../utils/Iopt';
import HeroAndUpdatedNews from '../components/HeroAndUpdatedNews';
import SidebarOpinion from '../components/SidebarOpinion';
import SpecialBoxNews from '../components/SpecialBoxNews';
import HomeSection01Lg from './HomeSection01Lg';
import HomeSection01Md from './HomeSection01Md';
import HomeSection01Xs from './HomeSection01Xs';

const HeroNewsSort = ({ article }) => {
    // const cat = PickCategoryByPageId(article.category, article.page);

    if (!article) {
        return null;
    }

    return (
        <Link to={article.link} className="hero-news-short link-area">
            <div className="hero-news-short-img image-area">
                <img src={Iopt(article.image, 320)} alt={article.title} />
                <PlayButton show={article.having_video} />
            </div>
            <div className="hero-news-short-title link-title">
                {article.pre_title ? <span>{article.pre_title} / </span> : null}
                {article.title}
            </div>
            {/* <div className="hero-news-short-footer">
                <Link to={`/${article.category}/${article.page}/`}>{cat.title}</Link>
            </div> */}
        </Link>
    );
};

const HeroNewsSortRow = ({ article1, article2, article3 }) => (
    <div className="row">
        <div className="col-12 col-xl-4 col-lg-4">
            <HeroNewsSort article={article1} />
        </div>
        <div className="col-12 col-xl-4 col-lg-4">
            <HeroNewsSort article={article2} />
        </div>
        <div className="col-12 col-xl-4 col-lg-4">
            <HeroNewsSort article={article3} />
        </div>
    </div>
);

function Left({ leadArticles, latestArticles, popularArticles }) {
    return (
        <div className="col col-840">
            <div>
                <HeroAndUpdatedNews
                    heroNewsArticle={leadArticles[0]}
                    leadArticles={leadArticles}
                    latestArticles={latestArticles}
                    popularArticles={popularArticles}
                />
                <HeroNewsSortRow
                    article1={leadArticles[1]}
                    article2={leadArticles[2]}
                    article3={leadArticles[3]}
                />

                <div className="row">
                    <div className="col-6 ">
                        <AdsYoutube show />
                    </div>
                    <div className="col-6 ">
                        <AdsGoogle show />
                    </div>
                    <div className="col d-none  d-lg-block mb-4">
                        {/* <Ads08 show /> */}
                        <XlHome03 />
                    </div>
                    <div className="col d-lg-none d-block mb-3">
                        <Ads05 show />
                    </div>
                    <div className="col d-none  d-lg-block">
                        <Ads055 show />
                    </div>
                    <div className="col-4 d-none d-lg-block">
                        {/* <Ads06 show name="gpt-passback6" /> */}
                        <XlHome04 />
                    </div>
                </div>

                <HeroNewsSortRow
                    article1={leadArticles[4]}
                    article2={leadArticles[5]}
                    article3={leadArticles[6]}
                />

                <div className="row">
                    <div className="col-12 d-none d-lg-block d-xl-block">
                        {/* <Ads14Full show mode="placeholder" /> */}
                        {/* <XlHome06 /> */}
                    </div>
                </div>

                <HeroNewsSortRow
                    article1={leadArticles[7]}
                    article2={leadArticles[8]}
                    article3={leadArticles[9]}
                />

                <div className="d-block d-md-none">
                    <LatestAndTopNews
                        latestArticles={latestArticles}
                        popularArticles={popularArticles}
                        maxArticle={5}
                    />
                </div>
            </div>
        </div>
    );
}
// function RightSidebar({ onlineOpinionArticleAr, speArticleAr, speArticleArLead }) {

function RightSidebar({ onlineOpinionArticleAr }) {
    return (
        <div className="col col-300-1">
            {/* <div className="d-none d-md-block mb-3">
                <XlWindy />
            </div> */}
            <div className="d-none d-md-block">
                <XlHome02 />
            </div>
            {/* <FootballBanner /> */}
            <SidebarOpinion
                title="মতামত"
                col="sidebar-category-news-title"
                articleAr={onlineOpinionArticleAr}
                maxArticle={2}
            />

            {/* <SideBarNewsWithLeadExclusive
                title="বাহান্ন স্পটলাইট"
                articleAr={speArticleAr}
                articleArLead={speArticleArLead}
                topNews
                hideMore
            /> */}

            <FbPagePreview />
            <div className="sidebar-converter">
                <a href="https://converter.bahannonews.com/" target="_blank" rel="noreferrer">
                    <img src={ConverterImg} alt="Converter" />
                </a>
            </div>
            <Ads19 show />
            {/* <XlHome07 /> */}
            <Ads061 show mode="placeholder" />
        </div>
    );
}

function HomeSection01Main({
    leadArticles,
    speArticleAr,
    popularArticles,
    eduArticleAr,
    onlineOpinionArticleAr,
    latestArticles
}) {
    const { configs } = InitInfo();
    const speArticleArLead = speArticleAr[0];
    const eduArticleArLead = eduArticleAr[0];

    return (
        <div className="container">
            <div className="row">
                <Left
                    leadArticles={leadArticles}
                    latestArticles={latestArticles}
                    popularArticles={popularArticles}
                />

                <RightSidebar
                    onlineOpinionArticleAr={onlineOpinionArticleAr}
                    speArticleAr={speArticleAr}
                    speArticleArLead={speArticleArLead}
                    eduArticleAr={eduArticleAr}
                    eduArticleArLead={eduArticleArLead}
                    configs={configs}
                />

                <div className="col-12">
                    <div className="special-feature">
                        <div className="sidebar-category-news-title-edu">
                            <div className="sidebar-category-news-title-edu-sub">
                                <Link to="/online/education/">শিক্ষা</Link>
                            </div>
                        </div>
                        <div className="row special-feature-padding">
                            {Object.values(eduArticleAr)
                                .slice(0, 4)
                                .map((row) => (
                                    <div className="col-12 col-xl-3 col-lg-3" key={row.sl}>
                                        <SpecialBoxNews article={row} key={row.sl} />
                                    </div>
                                ))}
                            <div className="col-12 special-feature-footer">
                                <Link to="online/education">আরও...</Link>
                            </div>
                        </div>
                    </div>
                </div>

                <div
                    className="col-12 d-none d-lg-block d-xl-block"
                    style={{ paddingLeft: 286, paddingRight: 286 }}
                >
                    {/* <Ads11 show mode="placeholder" /> */}
                    <XlHome08 />
                </div>
            </div>
        </div>
    );
}

function HomeSection01({
    speArticleAr,
    leadArticles,
    popularArticles,
    eduArticleAr,
    onlineOpinionArticleAr,
    latestArticles
}) {
    const browserData = BrowserInfo();
    if (browserData.sizeName === 'bs-xs') {
        return (
            <HomeSection01Xs
                leadArticles={leadArticles}
                speArticleAr={speArticleAr}
                popularArticles={popularArticles}
                eduArticleAr={eduArticleAr}
                onlineOpinionArticleAr={onlineOpinionArticleAr}
                latestArticles={latestArticles}
            />
        );
    }
    if (browserData.sizeName === 'bs-md') {
        return (
            <HomeSection01Md
                leadArticles={leadArticles}
                speArticleAr={speArticleAr}
                popularArticles={popularArticles}
                eduArticleAr={eduArticleAr}
                onlineOpinionArticleAr={onlineOpinionArticleAr}
                latestArticles={latestArticles}
            />
        );
    }

    if (browserData.sizeName === 'bs-lg') {
        return (
            <HomeSection01Lg
                leadArticles={leadArticles}
                speArticleAr={speArticleAr}
                popularArticles={popularArticles}
                eduArticleAr={eduArticleAr}
                onlineOpinionArticleAr={onlineOpinionArticleAr}
                latestArticles={latestArticles}
            />
        );
    }

    return (
        <HomeSection01Main
            leadArticles={leadArticles}
            speArticleAr={speArticleAr}
            popularArticles={popularArticles}
            eduArticleAr={eduArticleAr}
            onlineOpinionArticleAr={onlineOpinionArticleAr}
            latestArticles={latestArticles}
        />
    );
}

export default HomeSection01;
