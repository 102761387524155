/* eslint-disable no-unused-vars */
import { useState } from 'react';
import { Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { Ads05, Ads055, Ads08, Ads09, Ads14Full } from '../../../components/Ads';
import HeroNews from '../../../components/HeroNews';
import TimestampToDate from '../../../models/TimestampToDate';
import BoxNews from '../components/BoxNews';

export default function AllNewsSection({ articles }) {
    const [moreCount, setMoreCount] = useState(19);
    const [lastDate, setLastDate] = useState('');

    const handleMoreCount = () => {
        setMoreCount(moreCount + 10);
    };
    const totalCat = articles.length;

    const moreNews = Object.values(articles).sort((item1, item2) => {
        if (item1.time_published > item2.time_published) {
            return -1;
        }
        if (item1.time_published < item2.time_published) {
            return 1;
        }

        return 0;
    });
    const nextTime = new Date(articles[totalCat - 1]?.time_published * 1000);
    const nextDate = `${nextTime.getFullYear()}-${nextTime.getMonth() + 1}-${nextTime.getDate()}`;
    return (
        <div className="col col-840">
            <div className="row">
                <div className="col-12">
                    <div className="home-category-news-title">
                        <div className="home-category-news-title-sub">
                            <Link to="/news/all/">সর্বশেষ</Link>
                        </div>
                    </div>
                </div>
                <div className="col-12 col-510">
                    <HeroNews article={Object.values(moreNews)[0]} />
                </div>
                <div className=" col-12 col-lg-4 col-xl-4 col d-lg-none d-block mb-3">
                    <Ads05 show />
                </div>
                <div className="col-lg-4 col-xl-4 col-12 col-300 ">
                    <BoxNews article={Object.values(moreNews)[1]} />
                    <BoxNews article={Object.values(moreNews)[2]} />
                </div>
                <div className=" col-12 col-lg-4 col-xl-4 col d-none  d-lg-block d-xl-block mb-4">
                    <Ads08 show />
                </div>
                <div className=" col-12 col-lg-4 col-xl-4 col d-none  d-lg-block">
                    <Ads055 show />
                </div>
                <div className=" col-12 col-lg-4 col-xl-4 col-4 d-none d-lg-block">
                    <Ads09 show name="gpt-passback6" />
                </div>

                <div className=" col-12 col-lg-4 col-xl-4 col d-lg-none d-xl-none d-md-none mb-4">
                    <Ads09 show />
                </div>
                <div className="col-lg-4 col-xl-4 col-12  ">
                    <BoxNews article={Object.values(moreNews)[3]} />
                </div>
                <div className="col-lg-4 col-xl-4 col-12 ">
                    <BoxNews article={Object.values(moreNews)[4]} />
                </div>
                <div className=" col-12 col-lg-4 col-xl-4 col d-lg-none d-xl-none d-md-none mb-4">
                    <Ads08 show />
                </div>
                <div className="col-lg-4 col-xl-4 col-12 ">
                    <BoxNews article={Object.values(moreNews)[5]} />
                </div>
                <div className="col-lg-4 col-xl-4 col-12  ">
                    <BoxNews article={Object.values(moreNews)[6]} />
                </div>

                <div className=" col-12 col-lg-4 col-xl-4 col d-lg-none d-xl-none d-md-none mb-4">
                    <Ads055 show />
                </div>

                <div className="col-lg-4 col-xl-4 col-12 ">
                    <BoxNews article={Object.values(moreNews)[7]} />
                </div>
                <div className="col-lg-4 col-xl-4 col-12 ">
                    <BoxNews article={Object.values(moreNews)[8]} />
                </div>
                <Ads14Full show mode="placeholder" />

                <hr />
                {Object.values(moreNews)
                    .slice(9, `${moreCount}`)
                    .map((rows) => (
                        <div className="col-12" key={rows.sl}>
                            <Link to={rows.link} className="category-news link-area">
                                <div className="category-news-img">
                                    <img src={rows.image} alt="news" />
                                </div>
                                <div className="category-news-title link-title">
                                    <h4 className="link-title">{rows.title}</h4>
                                    <h5>{rows.summery}</h5>
                                    <p className="category-news-f-time float-right">
                                        <TimestampToDate time={rows.time_published} />
                                    </p>
                                </div>
                            </Link>
                        </div>
                    ))}
                <div className="col-12 text-center">
                    <Button onClick={handleMoreCount} className="category-news-more-btn">
                        {totalCat <= moreCount ? (
                            <Link to={`/archive/${nextDate}`}>শেষ </Link>
                        ) : (
                            'আরও'
                        )}
                    </Button>
                </div>
            </div>
        </div>
    );
}
