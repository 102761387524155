/* eslint-disable import/no-extraneous-dependencies */
import { createRoot } from 'react-dom/client';
import ReactGA from 'react-ga4';
import App from './projects/App';
import reportWebVitals from './reportWebVitals';

// Initialize Google Analytics 4
ReactGA.initialize('G-SX1R9B5RFZ');

// To track initial page load
ReactGA.send({ hitType: 'pageview', page: window.location.pathname + window.location.search });

const container = document.getElementById('root');
const root = createRoot(container); // createRoot(container!) if you use TypeScript
root.render(<App />);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
