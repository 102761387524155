import { useEffect, useState } from 'react';
import ConfigApi from '../../../../configs/ConfigApi';
import { useAuth } from '../../../contexts/AuthProvider';
import AxiosAuth from '../../../utils/AxiosAuth';
import Iopt from '../../../utils/Iopt';

function UserProfileSaveNewsPage() {
    const { currentUser } = useAuth();
    const [saveNews, setSaveNews] = useState([]);

    useEffect(() => {
        if (currentUser) {
            AxiosAuth.currentUserAuth(currentUser)
                .post(`${ConfigApi.API_USER_SAVE}`)
                .then((data) => {
                    setSaveNews(data.data);
                });
        }
    }, [currentUser]);

    return (
        <div className="col col-840">
            <div className="user-profile-title">
                <h4>Saved News</h4>
            </div>
            <hr />
            <div className="row gx-3 gy-3">
                {saveNews?.map((row) => (
                    <div className="col-12 col-lg-6 col-xl-6 col-md-6 " key={row.sl}>
                        <div className="user-profile-save-news">
                            <img
                                src={Iopt(process.env.REACT_APP_API_URL + row.image, 120)}
                                alt="im"
                            />
                            <p>{row.title}</p>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
}

export default UserProfileSaveNewsPage;
