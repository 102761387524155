import { useState } from 'react';
import { Modal } from 'react-bootstrap';
import { getTokenId } from '../../FirebaseInit';
import bahannoLogo from '../../assets/images/bahanno-logo.svg';

async function tokenFunc() {
    const data = await getTokenId(() => {});
    if (data) {
        // setHide(false);
    }
    // setHide(false);
    return data;
}

// function NotificationWindowMain() {
//     const notificationSt = !!(window?.Notification?.permission === 'default');
//     const [hide, setHide] = useState(notificationSt);
//     const [loading, setLoading] = useState(false);

//     const onClickYes = () => {
//         tokenFunc(setHide);
//         setHide(false);
//         setLoading(true);
//     };

//     if (!hide) {
//         return null;
//     }

//     return (
//         <div className="notification-set-window">
//             <img src={bahannoLogo} alt="Bahanno Logo" />
//             <div className="mt-3">বাহান্ন নিউজ থেকে নিয়মিত এলার্ট পেতে চান?</div>
//             <div className="mt-2">
//                 <button
//                     type="button"
//                     className="btn btn-danger btn-sm mr-3"
//                     onClick={() => {
//                         setHide(false);
//                     }}
//                 >
//                     না
//                 </button>
//                 <button
//                     type="button"
//                     className="btn btn-success btn-sm position-relative"
//                     onClick={onClickYes}
//                 >
//                     {loading ? (
//                         <div
//                             className="spinner-border spinner-border-sm text-white position-absolute"
//                             role="status"
//                             style={{
//                                 top: '6px',
//                                 left: '16px'
//                             }}
//                         >
//                             <span className="sr-only" />
//                         </div>
//                     ) : null}

//                     <span>হা</span>
//                 </button>
//             </div>
//         </div>
//     );
// }

function NotificationWindowMain2() {
    const notificationSt = !!(window?.Notification?.permission === 'default');
    const [hide, setHide] = useState(notificationSt);
    const [loading, setLoading] = useState(false);

    const onClickYes = () => {
        tokenFunc(setHide);
        setHide(false);
        setLoading(true);
    };

    const onClickNo = () => {
        setHide(false);
        localStorage.setItem('pushNotificationWindow', 'no');
    };

    // --Turn Off Window When User Set As No
    const localPushNotificationWindow = localStorage.getItem('pushNotificationWindow');
    if (localPushNotificationWindow === 'no') {
        return null;
    }

    if (!hide) {
        return null;
    }

    return (
        <Modal
            size="sm"
            style={{ background: 'none!important' }}
            className="notification-modal"
            show={hide}
            aria-labelledby="example-modal-sizes-title-lg"
            centered
            contentClassName="notification-set-window-modal-content"
            backdrop="static"
            backdropClassName="notification-set-window-modal-backdrop"
        >
            <Modal.Body>
                <div className="container">
                    <div className="notification-set-window-modal">
                        <img src={bahannoLogo} alt="Bahanno Logo" />
                        <div className="mt-3">বাহান্ন নিউজ থেকে নিয়মিত এলার্ট পেতে চান?</div>
                        <div className="mt-2">
                            <button
                                type="button"
                                className="btn btn-danger btn-sm mr-3"
                                onClick={onClickNo}
                            >
                                না
                            </button>
                            <button
                                type="button"
                                className="btn btn-success btn-sm position-relative"
                                onClick={onClickYes}
                                style={{ marginLeft: 10 }}
                            >
                                {loading ? (
                                    <div
                                        className="spinner-border spinner-border-sm text-white position-absolute"
                                        role="status"
                                        style={{
                                            top: '6px',
                                            left: '16px'
                                        }}
                                    >
                                        <span className="sr-only" />
                                    </div>
                                ) : null}

                                <span>হা</span>
                            </button>
                        </div>
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    );
}

function NotificationWindow() {
    if (!(navigator?.serviceWorker && window.Notification?.permission)) {
        return null;
    }
    return <NotificationWindowMain2 />;
}

export default NotificationWindow;
