import { useEffect, useState } from 'react';

import ConfigApi from '../../../../configs/ConfigApi';
import { useAuth } from '../../../contexts/AuthProvider';
import AxiosAuth from '../../../utils/AxiosAuth';
import Iopt from '../../../utils/Iopt';

function SubComment({ parentSl, comments }) {
    return (
        <>
            {comments?.map((row) => {
                if (parentSl === row?.parent_sl) {
                    return (
                        <div className="col-12 user-pro-com-item">
                            <div className="user-pro-com-info">
                                <h4>Comment</h4>
                                <hr />
                                <p>{row.comments}</p>
                                <small className="mb-3">{row.time_created}</small>
                            </div>
                        </div>
                    );
                }
                return null;
            })}
        </>
    );
}

function UserProfileCommentPage() {
    const { currentUser } = useAuth();
    const [comments, setComments] = useState([]);

    useEffect(() => {
        if (currentUser) {
            AxiosAuth.currentUserAuth(currentUser)
                .post(`${ConfigApi.API_USER_COMMENTS}`)
                .then((data) => {
                    setComments(data.data);
                });
        }
    }, [currentUser]);

    return (
        <div className="col col-840">
            <div className="user-profile-title">
                <h5>Comment History</h5>
                <div className="user-profile-title-div">
                    <input className="form-control" type="date" />
                </div>
                <div className="user-profile-title-div">
                    <button type="button">Clear History</button>
                </div>
            </div>
            <hr />
            <div className="row">
                {comments?.map((row) => {
                    if (row?.parent_sl === 0) {
                        return (
                            <>
                                <div className="col-12 user-pro-com-item" key={row.sl}>
                                    <div className="user-pro-com-img">
                                        <img
                                            src={Iopt(
                                                process.env.REACT_APP_API_URL + row.image,
                                                120
                                            )}
                                            alt="im"
                                        />
                                        <p>{row.title}</p>
                                    </div>
                                    <div className="user-pro-com-info">
                                        <h4>Comment</h4>
                                        <hr />
                                        <p>{row.comments}</p>
                                        <small className="mb-3">{row.time_created}</small>
                                        <SubComment parentSl={row.sl} comments={comments} />
                                        <button type="button" className="mb-2">
                                            View News
                                        </button>
                                    </div>
                                </div>
                            </>
                        );
                    }
                    return null;
                })}
            </div>
        </div>
    );
}

export default UserProfileCommentPage;
